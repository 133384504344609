<template>
  <div id="app">
    <el-container>
      <el-header>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <div class="logo_image">
            <a href="#" target="_blank"></a
            ><el-image
              :src="require('../assets/static/images/vector.svg')"
            ></el-image>
          </div>

          <div class="top-menu">
            <el-menu-item index="1" style="font-family: Inter">
              <font color="rgba(0,0,0,1)">Logo Maker</font>
            </el-menu-item>
            <el-menu-item index="2">
              <font color="rgba(0,0,0,1);">Logo Template</font>
            </el-menu-item>
            <el-menu-item index="3">
              <font color="rgba(0,0,0,1);">How It Works</font>
            </el-menu-item>
            <el-menu-item index="4">
              <font color="rgba(0,0,0,1);">Pricing</font>
            </el-menu-item>
          </div>

          <div class="login">
            <el-button
              style="
                border-radius: 10px;
                border: 1px solid rgba(103, 64, 225, 1);
              "
            >
              <font color="black"><strong>Log In</strong></font>
            </el-button>
            <el-button
              type="primary"
              style="
                border-radius: 10px;
                background-color: rgba(103, 64, 225, 1);
              "
              ><strong>Sign Up</strong></el-button
            >

           
          </div>
          <i class="el-icon-menu" @click="drawer = true" ></i>
          <el-drawer
            :visible.sync="drawer"
            :direction="direction"
            
          >
          <a href="#" style="text-decoration: none"
              ><p class="menu_li">Logo Maker</p></a
            >
            <a href="#" style="text-decoration: none"
              ><p class="menu_li">Logo Template</p></a
            >
            <a href="#" style="text-decoration: none"
              ><p class="menu_li">How It Works</p></a
            >
            <a href="#" style="text-decoration: none"
              ><p class="menu_li">Pricing</p></a
            >
            <a href="#" style="text-decoration: none"
              ><p class="menu_li">Log In</p></a
            >
            <a href="#" style="text-decoration: none"
              ><p class="menu_li">Sig Up</p></a
            >
          </el-drawer>
        </el-menu>
      </el-header>
      <el-main>
        <div class="header_bag">
          <div class="header_text">
            <h1 class="title">Logo Maker Easy</h1>
            <div class="hidden_text">
              <span style="font-size: 32px; color: #c75959">LogoE </span
              ><span
                style="
                  font-size: 20px;
                  font-family: 'Inter';
                  font-weight: 700;
                  line-height: 30px;
                "
                >is an AI logo generator that makes logos easier and more
                efficient for you to design logos，and you can complete all your
                designs in one place.</span
              >
            </div>
          </div>

          <el-input
            v-model="input"
            class="input_logo"
            placeholder="Logo name"
          ></el-input>
          <div>
            <el-button
              type="primary"
              style="
                background-color: rgba(103, 64, 225, 1);
                border-radius: 50px;
                line-height: 28px;
                font-size: 20px;
                width: 180px;
                margin-top: 20px;
                margin-bottom: 100px;
              "
              >Make a logo</el-button
            >
          </div>

          <el-image
            :src="require('../assets/static/images/v2_507.png')"
            class="block"
          ></el-image>
        </div>

        <div class="feature_logo"
          
        >    
            <h1>Featured logos <span style="color:rgb(103, 64, 225)">generated</span> by users</h1>
        </div>
        <div class="feature_img">
        <el-image
                :src="require('../assets/static/images/vector-1.svg')"
                class="feature_img1"
              ></el-image>
            </div>


        <div class="duo_img">
          <div class="img_left">
            <div class="imgs_left">
              <div class="one_img">
                <el-image
                  :src="require('../assets/static/images/v2_573.png')"
                  class="img1_margin"
                ></el-image>
              </div>
              <div class="two_img">
                <el-image
                  :src="require('../assets/static/images/v2_572.png')"
                  class="img_margin"
                ></el-image>
                <el-image
                  :src="require('../assets/static/images/v2_571.png')"
                  class="img_margin"
                ></el-image>
              </div>
              <div class="two_img">
                <el-image
                  :src="require('../assets/static/images/v2_570.png')"
                  class="img_margin"
                ></el-image>
                <el-image
                  :src="require('../assets/static/images/v2_567.png')"
                  class="img_margin"
                ></el-image>
              </div>
              <div class="two_img">
                <el-image
                  :src="require('../assets/static/images/v2_564.png')"
                  class="img_margin"
                ></el-image>
                <el-image
                  :src="require('../assets/static/images/v2_575.png')"
                  class="img_margin"
                ></el-image>
              </div>
              <div class="two_img">
                <el-image
                  :src="require('../assets/static/images/v2_576.png')"
                  class="img_margin"
                ></el-image>
                <el-image
                  :src="require('../assets/static/images/v2_577.png')"
                  class="img_margin"
                ></el-image>
              </div>
            </div>
          </div>
          <div class="img_right">
            <div class="imgs_right">
              <div class="two_img">
                <el-image
                  :src="require('../assets/static/images/v2_565.png')"
                  class="img_margin"
                ></el-image>
                <el-image
                  :src="require('../assets/static/images/v2_580.png')"
                  class="img_margin"
                ></el-image>
              </div>
              <div class="two_img">
                <el-image
                  :src="require('../assets/static/images/v2_574.png')"
                  class="img_margin"
                ></el-image>
                <el-image
                  :src="require('../assets/static/images/v2_568.png')"
                  class="img_margin"
                ></el-image>
              </div>
              <div class="two_img">
                <el-image
                  :src="require('../assets/static/images/v2_581.png')"
                  class="img_margin"
                ></el-image>
                <el-image
                  :src="require('../assets/static/images/v2_569.png')"
                  class="img_margin"
                ></el-image>
              </div>
              <div class="one_img">
                <el-image
                  :src="require('../assets/static/images/v2_566.png')"
                  class="img1_margin"
                ></el-image>
              </div>
              <div class="two_img">
                <el-image
                  :src="require('../assets/static/images/v2_579.png')"
                  class="img_margin"
                ></el-image>
                <el-image
                  :src="require('../assets/static/images/v2_578.png')"
                  class="img_margin"
                ></el-image>
              </div>
            </div>
          </div>
        </div>

        <div style="margin-top: 30px">
          <el-button
            style="
              background-color: rgba(103, 64, 225, 1);
              border-radius: 50px;
              line-height: 28px;
              font-size: 20px;
              width: 240px;
              margin-top: 20px;
            "
            type="primary"
            round
            >More logo templates</el-button
          >
        </div>
        <div style="margin-top: 20px">
          <el-button
            v-for="(button, index) in displayedButtons"
            :key="index"
            round
            >{{ button }}</el-button
          >
        </div>

        <div class="logo_make">
          <div class="logo_title">
            <h1 style="color: white; ">
              <strong
                >An AI logo maker that gives you more than a logo designer
                would</strong
              >
            </h1>
            <div style="margin-top: -20px">
              <el-image
                :src="require('../assets/static/images/vector-1-1.svg')"
                style="float: left; width: 120px"
              ></el-image>
            </div>
          </div>
          <div class="logo_full">
            <div class="logo_item">
              <div class="logo_img">
                <el-image
                  :src="require('../assets/static/images/frame.svg')"
                ></el-image>
              </div>
              <div class="logo_ctitle">
                <h2>Unique Design</h2>
              </div>
              <div class="logo_text">
                <p>
                  Extensive collection of logo templates, fonts, colors, symbols
                  and layouts tailored to your business
                </p>
              </div>
            </div>

            <div class="logo_item">
              <div class="logo_img">
                <el-image
                  :src="require('../assets/static/images/frame-3.svg')"
                ></el-image>
              </div>
              <div class="logo_ctitle">
                <h2>Vector Files</h2>
              </div>
              <div class="logo_text">
                <p>
                  Download any file format to ensure your logos are compatible
                  and optimized for any purpose - JPEG, PNG, SVG and more!
                </p>
              </div>
            </div>

            <div class="logo_item">
              <div class="logo_img">
                <el-image
                  :src="require('../assets/static/images/frame-5.png')"
                ></el-image>
              </div>
              <div class="logo_ctitle">
                <h2>Design Variations</h2>
              </div>
              <div class="logo_text">
                <p>
                  Download any file format to ensure your logos are compatible
                  and optimized for any purpose - JPEG, PNG, SVG and more!
                </p>
              </div>
            </div>

            <div class="logo_item">
              <div class="logo_img">
                <el-image
                  :src="require('../assets/static/images/frame-1.svg')"
                ></el-image>
              </div>
              <div class="logo_ctitle">
                <h2>Vector Files</h2>
              </div>
              <div class="logo_text">
                <p>
                  Extensive collection of logo templates, fonts and elements
                  tailored to your business
                </p>
              </div>
            </div>

            <div class="logo_item">
              <div class="logo_img">
                <el-image
                  :src="require('../assets/static/images/frame-2.svg')"
                ></el-image>
              </div>
              <div class="logo_ctitle">
                <h2>Social Media Profiles</h2>
              </div>
              <div class="logo_text">
                <p>
                  Extensive collection of logo templates, fonts and elements
                  tailored to your business
                </p>
              </div>
            </div>

            <div class="logo_item">
              <div class="logo_img">
                <el-image
                  :src="require('../assets/static/images/frame-4.svg')"
                ></el-image>
              </div>
              <div class="logo_ctitle">
                <h2>Business Card</h2>
              </div>
              <div class="logo_text">
                <p>
                  Extensive collection of logo templates, fonts and elements
                  tailored to your business
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="look_logo">
          <h1><strong>Which logo type are you looking for?</strong></h1>
        </div>
        <div style="font-size: 22px">
          <span>We have great logos for every industries</span>
        </div>
        <div class="people_full">
          <div class="people_row">
            <div class="people_item">
              <el-image
                :src="require('../assets/static/images/v2_804.png')"
                class="people_img"
              ></el-image>
              <p>Construction</p>
            </div>
            <div class="people_item">
              <el-image
                :src="require('../assets/static/images/v2_793.png')"
                class="people_img"
              ></el-image>
              <p>Religious</p>
            </div>
            <div class="people_item">
              <el-image
                :src="require('../assets/static/images/v2_809.png')"
                class="people_img"
              ></el-image>
              <p>Animals Pets</p>
            </div>
            <div class="people_item">
              <el-image
                :src="require('../assets/static/images/v2_810.png')"
                class="people_img"
              ></el-image>
              <p>Beauty Spa</p>
            </div>
            <div class="people_item">
              <el-image
                :src="require('../assets/static/images/v2_797.png')"
                class="people_img"
              ></el-image>
              <p>Internet</p>
            </div>

            <div class="people_item">
              <el-image
                :src="require('../assets/static/images/v2_792.png')"
                class="people_img"
              ></el-image>
              <p>Travel</p>
            </div>
            <div class="people_item">
              <el-image
                :src="require('../assets/static/images/v2_794.png')"
                class="people_img"
              ></el-image>
              <p>Entertainment</p>
            </div>
            <div class="people_item">
              <el-image
                :src="require('../assets/static/images/v2_795.png')"
                class="people_img"
              ></el-image>
              <p>Reataurant</p>
            </div>
            <div class="people_item">
              <el-image
                :src="require('../assets/static/images/v2_791.png')"
                class="people_img"
              ></el-image>
              <p>Leagi</p>
            </div>
            <div class="people_item">
              <el-image
                :src="require('../assets/static/images/v2_805.png')"
                class="people_img"
              ></el-image>
              <p>Home-Family</p>
            </div>

            <div class="people_item">
              <el-image
                :src="require('../assets/static/images/v2_807.png')"
                class="people_img"
              ></el-image>
              <p>Automotive</p>
            </div>
            <div class="people_item">
              <el-image
                :src="require('../assets/static/images/v2_808.png')"
                class="people_img"
              ></el-image>
              <p>Retail</p>
            </div>
            <div class="people_item">
              <el-image
                :src="require('../assets/static/images/v2_806.png')"
                class="people_img"
              ></el-image>
              <p>Medical Dental</p>
            </div>
            <div class="people_item">
              <el-image
                :src="require('../assets/static/images/v2_800.png')"
                class="people_img"
              ></el-image>
              <p>Sports Fitness</p>
            </div>
            <div class="people_item">
              <el-image
                :src="require('../assets/static/images/v2_803.png')"
                class="people_img"
              ></el-image>
              <p>Real Estate</p>
            </div>

            <div class="people_item">
              <el-image
                :src="require('../assets/static/images/v2_798.png')"
                class="people_img"
              ></el-image>
              <p>Technology</p>
            </div>
            <div class="people_item">
              <el-image
                :src="require('../assets/static/images/v2_801.png')"
                class="people_img"
              ></el-image>
              <p>Event</p>
            </div>
            <div class="people_item">
              <el-image
                :src="require('../assets/static/images/v2_799.png')"
                class="people_img"
              ></el-image>
              <p>Education</p>
            </div>
            <div class="people_item">
              <el-image
                :src="require('../assets/static/images/v2_796.png')"
                class="people_img"
              ></el-image>
              <p>Nonprofit</p>
            </div>
            <div class="people_item">
              <el-image
                :src="require('../assets/static/images/v2_802.png')"
                class="people_img"
              ></el-image>
              <p>Finance</p>
            </div>
          </div>
        </div>

        <div class="die_img">
          <div class="die_left">
            <div class="img1">
              <el-image
                :src="require('../assets/static/images/v2_703.png')"
                style="margin-top: -30px; border-radius: 20px"
              ></el-image>
            </div>
            <div class="img2">
              <el-image
                :src="require('../assets/static/images/v2_811.png')"
                style="margin-top: -30px; border-radius: 20px"
              ></el-image>
            </div>
            <div class="img3">
              <el-image
                :src="require('../assets/static/images/v2_812.png')"
                style="margin-top: -30px; border-radius: 20px"
              ></el-image>
            </div>
          </div>
          <div class="die_right">
            <div
              class="die_title"
              style="
                
                font-size: 32px;
                color: white;
                text-align: left;
                font-weigh: 700;
              "
            >
              <strong>Create a logo has never been easier!</strong>
            </div>
            <div
              class="die_text"
              style="
                max-width:400px;
                font-size: 20px;
                color: white;
                text-align: left;
              "
            >
              <p>
                maker that understands the best practice of a logo design for
                your business to generate a unique logo for you!
              </p>
              <el-button
                style="
                  color: rgb(147, 111, 252);
                  font-size: 20px;
                  border-radius: 50px;
                  line-height: 40px;
                  padding-left: 40px;
                  padding-right: 40px;
                "
                >Let’s make a logo</el-button
              >
            </div>
          </div>
        </div>

        <div class="logo_design_bgc">
          <div class="design_title">
            <h1><strong>More logo design resouces</strong></h1>
          </div>
          <div class="logo_design">
            <div class="design_item">
              <el-image
                :src="require('../assets/static/images/v2_712.png')" 
                style="width: 320px; height: 200px; border-radius: 10px"
              ></el-image>
              <p>
                The Psychology of Color in Driving Conversions: A Guide for
                Marketers
              </p>
            </div>
            <div class="design_item">
              <el-image
                :src="require('../assets/static/images/v2_713.png')" 
                style="width: 320px; height: 200px; border-radius: 10px"
                
              ></el-image>
              <p>
                An Alternative to Adobe Logo Maker, and Some Logo Examples
                Along!
              </p>
            </div>

            <div class="design_item">
              <el-image
                :src="require('../assets/static/images/v2_714.png')"
                style="width: 320px; height: 200px; border-radius: 10px"
              ></el-image>
              <p>10 Best New Logo Design Trends of 2023!</p>
            </div>
          </div>
        </div>
      </el-main>



     



      <el-footer>
        <div class="footer">
          <div class="footer_left" >
            <div
              class="footer_left_text"
              style="width: 330px; text-align: left;max-width:90vw;"
            >
              <p style="font-size: 14px; line-height: 20px">
                logomakerr.ai is an AI-powered logo generator that allows you to
                generate logo design templates that are easily customizable
                without design experience needed.
              </p>
            </div>
            <div class="footer_left_sign" style="float: left">
              <el-image
                :src="require('../assets/static/images/f_sign.png')"
                class="sign_img"
              ></el-image>
              <el-image
                :src="require('../assets/static/images/sign1.png')"
                class="sign_img"
              ></el-image>
              <el-image
                :src="require('../assets/static/images/p_sign.png')"
                class="sign_img"
              ></el-image>
              <el-image
                :src="require('../assets/static/images/sign2.png')"
                class="sign_img"
              >
              </el-image>
              <el-image
                :src="require('../assets/static/images/sign3.png')"
                class="sign_img"
              ></el-image>
            </div>
          </div>
          <div class="footer_right">
            <div class="footer_item" style="font-size: 14px">
              <h3
                style="
                  color: rgba(83, 64, 255, 1);
                  line-height: normal;
                  font-size: 18px;
                "
              >
                Product
              </h3>
              <p>Logo Maker</p>
              <p>Logo Idea</p>
              <p>How It Works</p>
              <p>Brand Kits</p>
              <p>Custom Logo</p>
              <p>Pricing</p>
            </div>
            <div class="footer_item" style="font-size: 14px">
              <h3
                style="
                  color: rgba(83, 64, 255, 1);
                  line-height: normal;
                  font-size: 18px;
                "
              >
                Company
              </h3>
              <p>About Us</p>
              <p>Affiliate</p>
              <p>Terms of Use</p>
              <p>Privacy Policy</p>
              <p>Refund Policy</p>
            </div>
            <div class="footer_item" style="font-size: 14px">
              <h3
                style="
                  color: rgba(83, 64, 255, 1);
                  line-height: normal;
                  font-size: 18px;
                "
              >
                Support
              </h3>
              <p>Blog</p>
              <p>FAQ</p>
              <p>Contact Us</p>
              <p>Site Map</p>
            </div>
          </div>
        </div>

        <div
          class="footer_left_bottom"
          style="margin-bottom: 20px; display: flex"
        >
          <p style="text-align: left">
            Made with
            <el-image
              :src="require('../assets/static/images/v2_740.png')"
              style="height: 14px"
            ></el-image>
            Shanghai. All rights reserved.
          </p>
        </div>





      </el-footer>








    </el-container>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },

  data() {
    return {
      buttons: [
        "Lawn Care logo",
        "Lawn Care logo1",
        "Lawn Care logo2",
        "Lawn Care logo3",
        "Lawn Care logo4",
        "Lawn Care logo5",
        "Lawn Care logo6",
        "Lawn Care logo7",
        "Lawn Care logo8",
        "Lawn Care logo1",
        "Lawn Care logo2",
        "Lawn Care logo3",
        "Lawn Care logo4",
        "Lawn Care logo5",
        "Lawn Care logo6",
        // 剩下的按钮依次添加...
      ],
      currentIndex: 0,
      displayedButtons: [],
      activeIndex: "1",
      activeIndex2: "1",
      input: "",
      drawer: false,
      direction: "rtl",
    };
  },
  created() {
    this.showNextButtons();
    setInterval(this.showNextButtons, 2000); // 每2秒切换一次按钮
  },
  methods: {
    showNextButtons() {
      const buttonCount = 7; // 每次显示的按钮数量
      const totalButtons = this.buttons.length;
      const startIndex = this.currentIndex % totalButtons;
      const endIndex = (startIndex + buttonCount) % totalButtons;

      if (startIndex <= endIndex) {
        this.displayedButtons = this.buttons.slice(startIndex, endIndex);
      } else {
        this.displayedButtons = [
          ...this.buttons.slice(startIndex),
          ...this.buttons.slice(0, endIndex),
        ];
      }

      this.currentIndex += 1;
    },

    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::v-deep .el-drawer {
  width: 60% !important;
}
.feature_img{
  margin-left:120px;
  margin-top:-10px;
}
.feature_img1{
  width: 200px; 
  height: 21px;
}
.input_logo{
  width: 350px; 
  margin-top: 30px;
}
.look_logo{
  line-height: 50px; 
  font-size: 25px;
}
.feature_logo{
  position: relative;
  margin-top: 120px;
  font-size:28px;
}
.menu_li {
  
  
  line-height: 60px;
  font-size: 15px;
  font-weight: 600;
  color: #222 !important;
  text-align: center;
  border-bottom: 1px solid hsla(0, 0%, 58.8%, 0.2);
}
.block {
  position: absolute;
  bottom: -35px;
  width: 1200px;
  
  clear: both;
  left: 50%;

  transform: translate(-50%);
}
@media screen and (max-width: 1280px){
.block{
  width:90%;
  
}
}
@media screen and (min-width: 768px){
  .el-icon-menu{
  display:none;
}

.login {
  
  right: 0px;
  top: 0px;
  height: 40px;
  line-height: 40px;
  margin-top: 10px;
  position: absolute;
}
.logo_image {
  position: absolute;
  left: 0px;
  top: 0px;
}
}
.design_title{
  line-height: 50px; 
  padding: 30px; 
  font-size: 30px;
}
@media screen and (max-width: 768px){
  .design_title{
  line-height: 30px; 
  padding: 20px; 
  font-size: 20px;
}
.block{
  width:90%;
  
}
  .logo_image {
    width: 40px;
    height:40px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.el-icon-menu {
    
    
    right: 0px;
  top: 0px;
  position: absolute;
font-size: 40px;
color:#6d6b6b;
}
.login {
  display:none;
}
.feature_logo{
  
  margin-top: 50px;
  font-size:12px;
}

.feature_img{
  margin-left:60px;
  margin-top:-10px;
}
.feature_img1{
  width: 100px; 
  height: 21px;
}
}


.el-header {
  color: black;
  font-weight: 400;

  text-align: center;
  line-height: 60px;
}
.el-menu {
  display: flex;
}
.el-menu-item {
  display: flex;
  font-size: 16px;
}
.top-menu {
  display: flex;
  margin: auto;
}


.el-footer {
  top: -100px;
  text-align: center;
}

.el-main {
  padding: 0px;
  color: #333;
  text-align: center;
}

.header_bag {
  background-size: 100% 100%;
  background: url("~@/assets/static/images/gradient.svg") center center;
  padding-top: 100px;
 
  position: relative;

  line-height: 60px;
}
.header_text {
  width: 520px;
  text-align: left;
  margin-left: 50vw;
  transform: translate(-50%);
  line-height: 30px;
  font-weight: 700;
  margin-top: 50px;
}
@media screen and (max-width: 768px) {
  .header_text {
  width: 80vw;
  }
}
body > .el-container {
  margin-bottom: 40px;
}



.img_full {
  display: flex;
  flex-direction: column;
}

.img_top {
  margin-bottom: 10px;
}

.logo_make {
  margin-top: 50px;
 
  background-color: rgb(147, 111, 252);
  border-radius: 18px;
  position: relative;
  overflow: hidden;
}

.logo_title {
  margin-left: 50%;
  transform: translate(-50%);
  width: 400px;
  line-height: 40px;
  text-align: left;
}

.logo_full {
  display: flex;

  margin-top: 100px;
  flex-flow: row wrap;
}

.logo_item {
  width: 380px;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 30px;
}

.logo_ctitle {
  color: white;
  margin: auto;
}

.logo_text {
  width: 350px;
  color: rgb(246, 245, 245);
  margin: auto;
}
@media screen and (max-width: 768px) {
  .logo_item {
  width: 40vw;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 30px;
}
.logo_text {
  width: 40vw;
  color: rgb(246, 245, 245);
  margin: auto;
  
}
.logo_text p{
  font-size: 12px;
  
}
.look_logo{
  line-height: 50px; 
  font-size: 15px;
}
}
.people_img {
  width: 110px;
  height: 110px;
}

.logo_design {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.die_img {
 
  background-color: rgb(147, 111, 252);
  margin-top: 100px;
  position: relative;
  display: flex;
}

.img1 {
  position: absolute;
  right: 6rem;
  width: 40rem;
  
}

.img2 {
  position: absolute;
  right: 12rem;
  width: 40rem;
  
}

.img3 {
  position: absolute;
  right: 18rem;
  width: 40rem;
  
}
@media screen and (max-width: 768px) {
  .img1 {
  position: absolute;
  left: 30vw;
  width: 64vw;
 
}

.img2 {
  position: absolute;
  left: 15vw;
  width: 64vw;
 
}

.img3 {
  position: absolute;
  left: 0vw;
  width: 64vw;
 
}



}





.logo_img {
  height: 90px;
  width: 90px;

  margin: 0 auto;
}

.people_row {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-flow: row wrap;
  margin-top: 40px;
}

.people_item {
  width: 215px;
}
@media screen and (max-width: 768px) {
  .people_item {
  width: 40vw;
  
}
}
.design_item {
  margin-left: 20px;
  width: 320px;

  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  line-height: 28px;
  font-size: 20px;
  font-weight: 700;
}

.footer {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.footer_left {
  float: left;
  
}

.footer_right {
  display: flex;
}

.footer_item {
  margin-right: 10vw;
  text-align: left;
}

.sign_img {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  margin-right: 15px;
}

@font-face {
  font-family: Zapfino-Regular;
  src: url("../assets/static/lzone___.TTF");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

.title {
  font-family: Zapfino-Regular;
  font-size: 100px;
  font-weight: 400;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .title {
 
  font-size: 50px;
    text-align: center;
    
}
}
.die_right {
  margin-top: 50px;
  flex: 1;
  margin-bottom: 150px;
  overflow: hidden;
}
.die_left {
  flex: 1;
  position: relative;
}

.logo_design_bgc {
  
  background-color: #f7f7f7;
}



.menu_item {
}
.el_menu {
  float: left;
}

@media screen and (max-width: 768px) {
  .hidden_text {
    
    line-height: 1.17;
    letter-spacing: .09px;
  }
.die_img{
  flex-direction: column;
}
  .die_right {
    margin-top: 170px;
    margin-bottom: 30px;
    padding:20px;
  }
  .die_title{
    justify-content: center;
  }
  .die_text{
    justify-content: center;
    display:flex;
    flex-direction: column;
    
  }
  .top-menu {
    display: none;
  }

  .die_left {
    
    height: 100px;
  }
  .img1 {
    height: 100px;
  }
  .logo_title {
    margin-left: 50%;
    transform: translate(-50%);
    width: 80%;
    line-height: 40px;

  }
  .logo_title h1{
    font-size: 18px;
  }
.logo_ctitle h2{
  font-size:16px;
}
.logo_ctitle p{
  font-size:12px;
}
  .input_logo{
    width:80vw;
  }

}

.one_img {
  width: 536px;
  
  margin-left: 50%;
  margin-top: 12px;
  transform: translate(-50%);
}

.two_img {
  width: 536px;
  
  display: flex;
  margin-top: 12px;
  margin-left: 50%;

  transform: translate(-50%);
}

.img1_margin {
  margin-right: 10px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 520px;
  
}
.img_margin {
  margin-right: 10px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 260px;
  
}
.img_margin :hover {
  transform: scale(1.1);
}
@media screen and (max-width: 768px){
  .img1_margin {

  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 90vw;
 
}
.img_margin {
 
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 45vw;
  
}
.one_img {
  width: 90vw;
  

}

.two_img {
  width: 94vw;
 
  display: flex;

}
}


@media screen and (min-width: 1200px) {
  .duo_img {
    display: flex;
    word-wrap: break-word;
    word-break: break-all;
    flex-flow: row wrap;
  }
  .img_left {
    flex: 1;

    position: relative;
  }
  .img_right {
    flex: 1;
    position: relative;
  }
  .imgs_left {
    width: 540px;
    position: absolute;
    right: 2px;
    padding: 0px;
  }
  .imgs_right {
    width: 540px;

    left: 2px;
    padding: 0px;
  }
}





</style>
